<template>
  <div>

  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useRoute, useRouter } from "vue-router";

export default {
  name: 'RedirectQr',
  data(){
    return {
      pid:""
    }
  },
  setup(){
   const route=useRoute();
   const router = useRouter();
   onMounted(async () => {
      await router.isReady();
      const pid = route.params.pid;
      console.log("pid: " ,pid);   
      if (pid)
      window.location.href = "https://nube.beghelli.it/#/products/" + pid;
    })
  },

}

</script>

